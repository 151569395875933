import axios from 'axios';

const api = axios.create({
  /*headers: {
    "Authorization": localStorage.getItem('token-admin'),
    "EnvironmentId": localStorage.getItem('environment_id')
  },*/
  baseURL: 'https://admin.skenn.com.br/api'
});

if (localStorage.getItem('token-admin') !== null) {
  api.defaults.headers.common['Authorization'] = localStorage.getItem('token-admin');
}

if (localStorage.getItem('environment_id') !== null) {
  api.defaults.headers.common['EnvironmentId'] = localStorage.getItem('environment_id');
}

api.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (localStorage.getItem('token-admin') !== null) {
    api.defaults.headers.common['Authorization'] = localStorage.getItem('token-admin');
  }

  if (localStorage.getItem('environment_id') !== null) {
    api.defaults.headers.common['EnvironmentId'] = localStorage.getItem('environment_id');
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


api.interceptors.response.use(
  response => { return response },
  error => {
    if (error.status === 401) {
      return Promise.reject(error);
    }

    if (localStorage.getItem('token-admin') !== null) {
      api.defaults.headers.common['Authorization'] = localStorage.getItem('token-admin');
    }

    if (localStorage.getItem('environment_id') !== null) {
      api.defaults.headers.common['EnvironmentId'] = localStorage.getItem('environment_id');
    }
  }
);


export default api;
