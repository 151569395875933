import api from './api.js';


class AuthService{

  
  constructor(){

    this.init = false;
    this.init_permissions = false;
    this.user = null;
    this.logged = false;
    this.user_permissions = [];

    this.listeners = [];

    this.user_listeners = [];
  }

  loadUser = async() => {
    let response = await api.get('/user' , { validateStatus: false });

    if(response.status === 200){
      this.user = response.data;
    }else{
      this.user = null;
    }
    
    this.init = true;
    this.triggerUserLoad();
  }

  loadPermissions = async() => {
    let response = await api.get('/permissions' , { validateStatus: false });

    this.user_permissions = {};
    if(response.status === 200){

      //this.user_permissions = response.data.filter(r => r.value > 0).map(r => r.module.code);
      response.data.map(r => {
        let module = r.module.code;
        let level = r.value;
        this.user_permissions[module] = level;
      });
    }

    this.init_permissions = true;

    this.triggerPermissionsLoaded();
  }

  loadEnvironment = async() => {
    let response = await api.get('/environments', { validateStatus: false});

    if (response.data.length > 1){
      return;
    }

    let currentDomain = window.location.hostname;

    let environment_id = response.data[0];
    
    if (environment_id === 1 && currentDomain !== 'admin.skenn.com.br') {
        window.location.href = 'https://admin.skenn.com.br';
        return;
    } else if (environment_id === 2 && currentDomain !== 'admin.pargan.com.br') {
        window.location.href = 'https://admin.pargan.com.br';
        return;
    }

    localStorage.setItem('environment_id', environment_id)


  }

  getUser = () => {
    return this.user;
  }

  logout = async() => {
    let response = await api.post('/logout' , { validateStatus: false });
    this.loadUser();
  }

  getUserPermissions = () => {
    return this.user_permissions;
  }

  onPermissionsLoad = (listener) => {
    this.listeners.push(listener);
    listener(this.user_permissions , this.init_permissions)
  }

  onUserLoad = (listener) => {
    this.user_listeners.push(listener);
    listener(this.user , this.init)
  }

  hasPermission = (permission , level = 1) => {

    let permissions = permission.split(',');

    for(let i = 0;i <= permissions.length-1;i++){
      //if(this.user_permissions.includes(permissions[i])){
      if(this.user_permissions[permissions[i]] >= level){
        return true;
      }
    }

    return false;    
  }

  triggerPermissionsLoaded = () => {
    this.listeners.map(listener => listener(this.user_permissions , this.init_permissions))
  }


  triggerUserLoad = () => {
    this.user_listeners.map(listener => listener(this.user , this.init))
  }
}


export default new AuthService;
